import { createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState = {
  mainPageForm: "auth",//Какую форму отображаем на главной странице для неавторизованых
};

// Create a slice
const paramsSlice = createSlice({
  name: "params",
  initialState,
  reducers: {
    setParam: (state, action) => {
      const {param, data} = action.payload;
      state[param] = data;
    },
  }
});

export const { setParam } = paramsSlice.actions;

export default paramsSlice.reducer;
