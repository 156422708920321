import {baseUrl} from "@utils/constants";
import dayjs from "dayjs";
import classNames from "classnames";

import styles from './styles.scss';

export const Avatar = ({user, className = ''}) => {

    const defaultPic = require('@assets/default.webp');

    let photo = user?.photo_url ? baseUrl + user.photo_url : defaultPic;

    const hasPrivateAccess = user?.private_access_date && dayjs(user?.private_access_date).unix() > dayjs().unix();

    return <div className={classNames(styles.avatar, className, hasPrivateAccess && styles.hasPrivateAccess)}>
        <img src={photo} alt="avatar" className={styles.image}/>
    </div>
}