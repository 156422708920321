import axios from 'axios';
import {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Button} from '@components/Button';
import {baseUrl} from '@utils/constants';
import {setParam} from '@slices/paramsSlice';
import {setUser} from '@slices/userSlice';
import {Error, Input, Title} from '../elements';
import styles from './styles.scss';

export const ForgotPassword = () => {

	const dispatch = useDispatch();

	const [email, setEmail] = useState('');
	const [formError, setFormError] = useState('');

	//Статус запроса. Возможные значения: waiting, requested
	const [requestStatus, setRequestStatus] = useState('waiting');

	const isEmailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

	const isFormValid = isEmailValid;

	const {t} = useTranslation();

	useEffect(() => {
		//При измененнии данных формы - убираем ошибку
		setFormError('');
	}, [email]);

	useEffect(() => {
		document.addEventListener('keydown', onKeyDown, false);
		return () => document.removeEventListener('keydown', onKeyDown);
		//Вынесли в отдельный useEffect с зависимостью от данных формы, тк в другом случае провряется всегда начальный state
	}, [email, requestStatus]);

	const onLoginButtonClick = () => {
		if(requestStatus != 'waiting'){
			//Отправлять запрос только если форма находится в ожидании отправки
			return;
		}

		if(isFormValid){
			setRequestStatus('requested');
			setFormError('');

			//API-запрос на авторизацию. Не выношу все в thunks/fetches, чтобы держать все на одной странице. Так проще поддерживать
			//Если нужно - вынесем отдельно
			axios
				.post(baseUrl + '/api/auth/forgot-password', {
					email
				})
				.then(response => {
					toast.success(t('main.forms.forgotPassword.linkSent'));
					setEmail('');
					toAuth();
				})
				.catch(error => {
					setFormError(t('main.forms.errors.requestFailed'));
				})
				.finally(() => {
					setRequestStatus('waiting');
				});

		}else{
			const errors = [];
			if(!isEmailValid){
				errors.push(t('main.forms.errors.specifyValidEmail'));
			}
			if(errors){
				setFormError(errors.join('<br/>'));
			}
		}
	}

	const toAuth = () => {
		dispatch(setParam({param: 'mainPageForm', data: 'auth'}));
	}

	const onKeyDown = e => {
		if (['NumpadEnter', 'Enter'].includes(e.code)) {
			onLoginButtonClick();
		}
	};

	return <div className={styles.forgotPassword}>
		<Title>{t('main.forms.forgotPassword.title')}</Title>
		<Input labelText={t('main.forms.email.label')} placeholder={t('main.forms.email.placeholder')} onChange={setEmail} value={email} />
		<Error>{formError}</Error>
		<Button onClick={onLoginButtonClick} disabled={requestStatus == 'requested'}>{t('main.forms.forgotPassword.button')}</Button>
		<div className={styles.rememberPasswordWrapper}>{t('main.forms.forgotPassword.rememberPassword')} <span onClick={toAuth}>{t('main.forms.forgotPassword.toAuth')}</span></div>
	</div>;
}