import classNames from 'classnames';
import {Link, matchPath, useLocation, useMatch, useMatches, useParams} from "react-router-dom";
import { usePages } from "@pages/hooks/usePages";
import styles from './styles.scss';

export const PageList = () => {
  const pages = usePages();
  const location = useLocation();

  return pages.map((page, idx) => {

    const active = !!(page.path && matchPath(page.path, location.pathname));

    const classnames = classNames({
      [styles.link]: true,
      [styles.telegram]: page.code == 'telegram',
      [styles.private]: page.code == 'private',
      [styles.active]: active
    });

    const target = page.isExternal ? '_blank' : '_self';

    return page.inHeader && <li key={idx}>
      <Link to={page.link} className={classnames} target={target}>
        {page.name}
      </Link>
    </li>
  })
};
