// src/features/postsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { tgAuth } from "../../fetches";

// Define the initial state
const initialState = {
  data: {},
  token: "",
  status: "idle",
  error: null,
};

const storedToken = localStorage.getItem("token");
if (storedToken) {
  initialState.token = storedToken;
}
const storedData = localStorage.getItem("data");
if (storedData) {
  try {
    initialState.data = JSON.parse(storedData);
  }catch (e) {
    initialState.data = {};
  }
}

// Define an async thunk for fetching posts
export const fetchUser = createAsyncThunk("user/fetchUser", async (response) =>
  tgAuth(response)
);

// Create a slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = "";
      localStorage.clear();
      sessionStorage.clear();
    },
    setUser: (state, action) => {
      state.token = action.payload.token;
      state.data = action.payload.data;
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("data", JSON.stringify(action.payload.data));
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (!state.token) {
          localStorage.setItem("token", action.payload.data.jwt);
          state.token = action.payload.data.jwt;
        }
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { logout, setUser } = userSlice.actions;

export default userSlice.reducer;
