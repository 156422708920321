import axios from 'axios';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Button} from '@components/Button';
import {baseUrl} from '@utils/constants';
import {setParam} from '@slices/paramsSlice';
import {setUser} from '@slices/userSlice';
import {Error, Input, Title} from '../elements';
import styles from './styles.scss';

export const Auth = () => {

	const dispatch = useDispatch();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [formError, setFormError] = useState('');

	const [requestStatus, setRequestStatus] = useState('waiting');

	const isPasswordValid = password.length >= 1;
	const isEmailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

	const isFormValid = isEmailValid && isPasswordValid;

	const {t} = useTranslation();

	useEffect(() => {
		//При измененнии данных формы - убираем ошибку
		setFormError('');
	}, [email, password]);

	useEffect(() => {
		document.addEventListener('keydown', onKeyDown, false);
		return () => document.removeEventListener('keydown', onKeyDown);
		//Вынесли в отдельный useEffect с зависимостью от данных формы, тк в другом случае провряется всегда начальный state
	}, [email, password, requestStatus]);

	const onLoginButtonClick = () => {
		if(requestStatus != 'waiting'){
			//Отправлять запрос только если форма находится в ожидании отправки
			return;
		}

		if(isFormValid){
			setRequestStatus('requested');
			setFormError('');

			//API-запрос на авторизацию. Не выношу все в thunks/fetches, чтобы держать все на одной странице. Так проще поддерживать
			//Если нужно - вынесем отдельно
			axios
				.post(baseUrl + '/api/auth/local', {
					identifier: email,
					password: password
				})
				.then(response => {
					dispatch(setUser({token: response.data.jwt, data: response.data.user}))
				})
				.catch(error => {
					if(error.response?.status == 400){
						setFormError(t('main.forms.errors.wrongCredentials'));
					}else{
						setFormError(t('main.forms.errors.requestFailed'));
					}
				})
				.finally(() => {
					setRequestStatus('waiting');
				});
		}else{
			const errors = [];
			if(!isEmailValid){
				errors.push(t('main.forms.errors.specifyValidEmail'));
			}
			if(!isPasswordValid){
				errors.push(t('main.forms.errors.specifyPassword'));
			}
			if(errors){
				setFormError(errors.join('<br/>'));
			}
		}
	}

	const onKeyDown = e => {
		if (['NumpadEnter', 'Enter'].includes(e.code)) {
			onLoginButtonClick();
		}
	};

	const toRegistration = () => {
		dispatch(setParam({param: 'mainPageForm', data: 'registration'}))
	}

	const toForgotPassword = () => {
		dispatch(setParam({param: 'mainPageForm', data: 'forgotPassword'}))
	}

	return <div className={styles.auth}>
		<Title>{t('main.forms.auth.title')}</Title>
		<Input labelText={t('main.forms.email.label')} placeholder={t('main.forms.email.placeholder')} onChange={setEmail} value={email} />
		<Input type={'password'} labelText={t('main.forms.password.label')} onChange={setPassword} placeholder={t('main.forms.password.placeholder')}/>
		<Error>{formError}</Error>
		<div className={styles.forgotPassword} onClick={toForgotPassword}>
			{t('main.forms.auth.forgotPassword')}
		</div>
		<Button onClick={onLoginButtonClick} disabled={requestStatus == 'requested'}>{t('main.forms.auth.button')}</Button>
		<div className={styles.noAccountWrapper}>
			{t('main.forms.auth.noAccount')} <span className={styles.toRegistration} onClick={toRegistration}>{t('main.forms.auth.register')}</span>
		</div>
	</div>;
}