import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {addComment} from '../../../../utils/redux/slices/postSlice';
import styles from './styles.scss';
import {Button} from "../../../../Components/Button";
import {useState} from "react";
import axios from "axios";
import {baseUrl, headers} from "../../../../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {Avatar} from "@components/elememts";

export const Comments = () => {

   const {post, linkId} = useSelector((state) => state.post);
   const user = useSelector((state) => state.user);
   let comments = Object.values(post?.comments?.data || []).sort((a, b) => a.attributes.createdAt < b.attributes.createdAt ? 1 : -1) || [];

   const [text, setText] = useState('');

   const [requested, setRequested] = useState(false);
   const dispatch = useDispatch();

   const {t} = useTranslation();

   const sendComment = () => {
      if(requested){
         return;
      }

      setRequested(true);

      axios
          .post(baseUrl + '/api/comments', {
             data: {
                text: text,
                post: linkId,
                user: user.data.id
             }
          }, headers())
          .then(response => {
             setText('');
             dispatch(addComment({
                ...response.data.data,
                attributes: {
                   ...response.data.data.attributes,
                   user: {data: {attributes: user.data}}
                }
             }));
          })
          .catch(error => {

          })
          .finally(() => {
             setRequested(false);
          });
   };

   return <div className={styles.comments}>
      <div className={styles.title}>{t('details.comments.title')}</div>
      <div className={styles.list}>
         {comments.length ? comments.map(comment => <div key={comment.id} className={styles.item}>
            <Avatar user={comment.attributes?.user?.data?.attributes} className={styles.avatar}/>
            <div className={styles.comment}>
               <div className={styles.itemText}>{comment.attributes?.text}</div>
               <div className={styles.itemMeta}>
                  <div>{t('details.comments.author')}: {comment.attributes?.user?.data?.attributes?.username || t('details.comments.anonymous')}</div>
                  <div>{dayjs(comment.attributes?.createdAt).format('DD.MM.YYYY HH:mm')}</div>
               </div>
            </div>
         </div>) : <div>{t('details.comments.noComments')}</div>}
      </div>
      <div className={styles.formWrapper}>
         <textarea className={styles.text} value={text} onChange={e => setText(e.target.value)}></textarea>
         <Button disabled={text.length <= 1 || requested} onClick={sendComment}>{t('details.comments.send')}</Button>
      </div>
   </div>;
};