import styled from "@emotion/styled";
import {media} from '@styles/media';
import { StyleConstants } from "@styles/variables";

export const Wrapper = styled.section`
  background: ${StyleConstants.COLORS.BLACK};
  min-height: calc(100vh - 70px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
  ${media.small} {
    align-items: flex-start;
  }
`;
