import styled from "@emotion/styled";
import { Mixins } from "@styles/variables";
import { Link } from "react-router-dom";

export const Button = ({ children, link, isExternal, disabled, className,  ...rest }) => {

  //Заблокированная кнопка. Стили
  const style = disabled ? {
    filter: 'grayscale(0.5)',
    pointerEvents: 'none'
  } : {};

  if (link) {
    return (
      <StyledLinkButton
        to={link}
        target={isExternal && "_blank"}
        rel={isExternal && "noreferrer"}
        className={className}
      >
        {children}
      </StyledLinkButton>
    );
  }

  if (!link) {
    return <StyledButton {...rest} style={style} className={className}>{children}</StyledButton>;
  }
};

export const StyledLinkButton = styled(Link)`
  ${Mixins.BUTTON}
`;

export const StyledButton = styled.button`
  ${Mixins.BUTTON}
`;
