import classnames from 'classnames';
import styles from './styles.scss';

export const Input = props => {

  const {type = 'text', style = {}, placeholder, value, className, errorText} = props;

  const onChange = (e) => {
    if (typeof props.onChange === 'function') {
      props.onChange(e.target.value);
    }
  };

  return <div className={classnames(styles.inputWrapper, props?.wrapperClassName || '')}>

    {props.labelText && <label className={props.labelClassName}>
      {props.labelText}
    </label>}

    <input onChange={onChange} placeholder={placeholder} value={value} className={className} style={style} type={type} />

    {errorText && <div className={classnames(styles.errorText)}>
      {errorText}
    </div>}
  </div>;

}