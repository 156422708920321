//import {initSentry} from '@utils/helpers';
import React from "react";
import ReactDOM from "react-dom/client";

import "./Locales/i18n";
import { HelmetProvider } from "react-helmet-async";

// this library let you make language translations
// for static information on your website
// if you see next construction t("key")
// that's the way to translate this data
// all you that you need you can find in directory "../public/locales"
// folders named as languages that we have provided
// inside you would see json files with translation keys values pairs
// default language is russian

import App from "./Pages/App";
import { Provider } from "react-redux";
import { store } from "@utils/redux/store";
import './styles/mixins.scss';

//В продакшен-режиме все ошибки будут отправляться в Sentry
//process.env.NODE_ENV == 'production' && initSentry();

const root = ReactDOM.createRoot(document.getElementById("root"));

// disabled strict to avoid unnecessary reloading
root.render(
  <HelmetProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </HelmetProvider>
);
