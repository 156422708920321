import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import monobank from "@assets/icons/monobank.svg";
import paypal from "@assets/icons/paypal.svg";
import bitcoin from "@assets/icons/bitcoin.svg";
import usdt from "@assets/icons/usdt.svg";
import {fetchWallets} from '@slices/walletsSlice';

import styles from './styles.scss';

export const Wallets = () => {
  const {wallets = []} = useSelector((state) => state.wallets);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!wallets.length) {
      dispatch(fetchWallets());
    }
  }, []);

  const icons = {'Mono': monobank, 'PayPal': paypal, 'Bitcoin': bitcoin, 'USDT': usdt}

  return <div className={styles.wallets}><div className={styles.title}>{t("details.payments")}</div>
    {wallets.map((wallet, index) => <div key={index} className={styles.wallet}>
      {icons[wallet.attributes.name] && <img src={icons[wallet.attributes.name]} className={styles.icon} alt=""/>} {wallet.attributes.wallet_id}
    </div>)}
  </div>
}