import DisableDevtool from "disable-devtool";
import {useEffect} from "react";
import {Toaster} from 'react-hot-toast';
import {BrowserRouter} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {CustomRoutes} from "./Routes";
import {GlobalStyle} from "@styles/global-styles";
import {AdultContentAlert} from "@components/Modules/AdultContentAlert";

//Определяем режим разработки
const isDevMode = process.env.NODE_ENV === 'development';

function App() {
  useEffect(() => {
    if (!isDevMode) {
      //Вне режима разработки: запретить DevTools и контекстное меню
      //DisableDevtool();
      document.addEventListener("contextmenu", preventContextmenu);
      return () => document.removeEventListener("contextmenu", preventContextmenu);
    }
  }, []);

  return (
    <BrowserRouter>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link
          href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <AdultContentAlert/>
      <CustomRoutes/>
      <GlobalStyle/>
      <Toaster/>
    </BrowserRouter>
  );
}

export default App;

//Метод предотвращения открытия контексного меню для слушаетля
const preventContextmenu = e => e.preventDefault();