import {Modal} from "@components/Modules";
import styles from "./styles.scss";
import {useEffect, useState} from "react";
import classNames from "classnames";
import {baseUrl} from "@utils/constants";
import axios from "axios";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {Spiner} from "@components/Spinner";
import {useTranslation} from "react-i18next";

export const TopUpModal = (props) => {

    const {user = {}} = useSelector((state) => state.pageData?.private) || {};

    const [amount, setAmount] = useState(10);
    const [coin, setCoin] = useState('USDT');
    const [cryptoAmount, setCryptoAmount] = useState(0);

    const [requested, setRequested] = useState(false);

    const [botInvoiceUrl, setBotInvoiceUrl] = useState(null);
    const [webInvoiceUrl, setWebInvoiceUrl] = useState(null);

    const {t} = useTranslation();

    const amounts = [5, 10, 20, 50];
    const coins = ['USDT', 'BTC', 'ETH', 'TON', 'TRX', 'LTC'];

    useEffect(() => {
        cancel();
    }, [coin, amount]);

    const cancel = () => {
        setBotInvoiceUrl(null);
        setWebInvoiceUrl(null);
        setCryptoAmount(0);
    }

    const getInvoice = () => {
        //Получаем ссылку на оплату
        if(requested){
            return;
        }
        setRequested(true);
        const url = `${baseUrl}/api/payments/create-invoice/`;
        axios
            .get(url, {params: {amount, coin, userID: user?.id}})
            .then(response => {
                if(response.data.status){
                    const {bot_invoice_url = '', web_app_invoice_url = '', amount = 0} = response.data?.data?.invoice || {};
                    setBotInvoiceUrl(bot_invoice_url);
                    setWebInvoiceUrl(web_app_invoice_url);
                    setCryptoAmount(amount);
                }else{
                    toast.error(response.data.data.message);
                }

            })
            .catch(error => {
                toast.error(t('common.errors.requestFailed'));
            })
            .finally(() => setRequested(false));
    };

    return <Modal className={styles.topUpModal} opened={props.opened} onClose={props.onClose}>
        <div className={styles.topUpModalContent}>
            <div className={styles.title}>{t('private.modals.topUp.title')}</div>
            <div className={styles.amount}>
                <div className={styles.subtitle}>{t('private.modals.topUp.amount')}:</div>
                <div className={styles.options}>
                    {amounts.map((option, index) =>
                        <div key={index} className={classNames(styles.option, amount === option && styles.active)}
                             onClick={() => setAmount(option)}>
                            {option} usd
                        </div>)
                    }
                </div>

            </div>
            <div className={styles.coin}>
                <div className={styles.subtitle}>{t('private.modals.topUp.crypto')}:</div>
                <div className={styles.options}>
                    {coins.map((option, index) =>
                        <div key={index} className={classNames(styles.option, coin === option && styles.active)} onClick={() => setCoin(option)}>
                            {option}
                        </div>)
                    }
                </div>
            </div>

            <div className={styles.buttons}>
                <div className={styles.subtitle}>CryptoBot</div>
                {cryptoAmount > 0 && <div className={styles.cryptoAmount}>{cryptoAmount} {coin}</div>}
                {!cryptoAmount && <div className={styles.button} onClick={getInvoice}>{t('private.modals.topUp.buttons.getPaymentLink')}</div>}
                {botInvoiceUrl && <div className={classNames(styles.button, styles.link)} onClick={() => window.open(botInvoiceUrl)}>{t('private.modals.topUp.buttons.payInTelegram')}</div>}
                {false && webInvoiceUrl && <div className={classNames(styles.button, styles.link)} onClick={() => window.open(webInvoiceUrl)}>{t('private.modals.topUp.buttons.payOnSite')}</div>}
                {cryptoAmount > 0 && <div className={classNames(styles.button, styles.cancel)} onClick={cancel}>{t('private.modals.topUp.buttons.cancel')}</div>}
            </div>
        </div>

        {requested && <div className={styles.overlay}>
            <Spiner/>
        </div>}
    </Modal>
};