import styles from './styles.scss';
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import classnames from "classnames";
import {GetPrivateAccessModal, TopUpModal} from "./modals";
import {useState} from "react";
import {useTranslation} from "react-i18next";

export const PrivateAccessStatus = () => {

    const {user = {}} = useSelector((state) => state.pageData?.private) || {};

    const [topUpModalOpened, setTopUpModalOpened] = useState(false);
    const [getPrivateAccessModalOpened, setGetPrivateAccessModalOpened] = useState(false);

    const {t} = useTranslation();

    const privateAccessDate = user?.private_access_date;

    const privateAccessDaysLeft = dayjs(privateAccessDate).diff(dayjs(), 'days');

    const privateAccessDateClassNames = {
        [styles.expired]: privateAccessDaysLeft <= 0,
        [styles.expires]: privateAccessDaysLeft > 0,
        [styles.active]: privateAccessDaysLeft > 7,
    };

    return <div className={styles.wrapper}>
        <div className={styles.privatAccessStatus}>
            <div className={styles.accessDate}>
                <div>{t('private.subscription.title')}:
                    <span className={classnames(privateAccessDateClassNames)}>
                    {privateAccessDate ? dayjs(privateAccessDate).format('DD.MM.YYYY HH:mm') : ' - '}
                </span>
                </div>
                <div className={styles.prolongButton} onClick={() => setGetPrivateAccessModalOpened(true)}>
                    {t('private.subscription.button')}
                </div>
            </div>

            <div className={styles.balance}>
                <div>{t('private.balance.title')}: <span>${+user.balance}</span></div>
                <div className={styles.topUpButton} onClick={() => setTopUpModalOpened(true)}>
                    {t('private.balance.button')}
                </div>
            </div>
        </div>


        <div className={styles.text}>Только эксклюзивный контент! Публикуется стабильно раз в 3 дня</div>

        <TopUpModal opened={topUpModalOpened} onClose={() => setTopUpModalOpened(false)}/>
        <GetPrivateAccessModal opened={getPrivateAccessModalOpened}
                               onClose={() => setGetPrivateAccessModalOpened(false)}/>
    </div>;
};