import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import styles from './styles.scss';

export const WhoreLink = () => {

  const {t} = useTranslation();

  const {whoreLink = ''} = useSelector((state) => state.post?.post) || {};

  if(!whoreLink || whoreLink.length <= 0){
    return null;
  }

  let Link = <a href={whoreLink} target="_blank" rel="noreferrer">{whoreLink}</a>;

  if(whoreLink.includes('vk.com') || whoreLink.includes('vk.ru')){
    Link = <>{t('details.vkProfile')}: <a href={whoreLink} target="_blank" rel="noreferrer">{t('details.go')}</a></>;
  }


  return <div className={styles.whoreLink}>
    {Link}
  </div>;
};