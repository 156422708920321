import styles from './styles.scss';
import {Button} from "@components/elememts";
import {useEffect, useState} from "react";
import i18n from "i18next";
import {arrayFromLanguageList} from "@components/Header/utils/arrayFromLanguageList";
import {useTranslation} from "react-i18next";

export const AdultContentAlert = () => {

    const adultContentAgeConfirmed = localStorage.getItem('adultContentAgeConfirmed') === 'true';

    const {t} = useTranslation();

    const [ageConfirmed, setAgeConfirmed] = useState(adultContentAgeConfirmed);
    const languages = Object.keys(i18n?.options.resources);

    const proceed = () => {
        //Записываем в localStorage, что пользователь подтвердил возраст
        localStorage.setItem('adultContentAgeConfirmed', 'true');

        //Закрываем окно
        setAgeConfirmed(true);
    };

    useEffect(() => {
        document.body.style.overflow = ageConfirmed ? 'auto' : 'hidden';
    }, [ageConfirmed]);

    const exit = () => {
        //Выход из сайта на Гугл
        window.location.href = 'https://www.google.com/';
    };

    const handleLanguageChange = (e) => {
        //Нужно менять язык и из этой модалки, так как она перекрывает все остальное
        const language = e.target.value;
        i18n.changeLanguage(language);
    };

    return ageConfirmed || <div className={styles.overlay}>
        <div className={styles.content}>
            <select onChange={handleLanguageChange} className={styles.select}>
                {languages.map((lng) => <option key={lng} value={lng} selected={lng === i18n.language}>
                    {t(`languages.${lng}`)}
                </option>)}
            </select>
            <div className={styles.title}>{t('adultContentAlert.title')}</div>
            <div className={styles.text}>
                {t('adultContentAlert.text', {returnObjects: true }).map((text, index) => <p key={index}>{text}</p>)}
            </div>
            <div className={styles.subtitle}>
                {t('adultContentAlert.subtitle')}
            </div>
            <div className={styles.buttons}>
                <Button className={styles.button} onClick={proceed}>{t('adultContentAlert.buttons.go')}</Button>
                <Button className={styles.button} color={'red'} onClick={exit}>{t('adultContentAlert.buttons.exit')}</Button>
            </div>
        </div>
    </div>;
}