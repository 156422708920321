import React, {CSSProperties, useEffect, useState} from 'react';
import classNames from 'classnames';

import styles from './styles.scss';

export const Modal = (props) => {
    const [opened, setOpened] = useState(props.opened);

    useEffect(() => {
        setOpened(props.opened);
    }, [props.opened]);

    const close = () => {
        setOpened(false);
        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    };

    useEffect(() => {
        if(opened){
            //Закрытие при нажатии на Esc
            window.addEventListener('keydown', onKeyDown);
        }
    }, [opened]);

    const onKeyDown = e => {
        if (e.code == 'Escape') {
            close();
            window.removeEventListener('keydown', onKeyDown);
        }
    };

    return <div className={classNames(styles.modal, opened && styles.opened)}>
        <div className={classNames(styles.modalBody, props.className)} style={{...props.style}}>
            <span className={styles.modalClose} onClick={close}>✖</span>
            <div className={classNames(styles.modalContent)}>
                {props.children}
            </div>
        </div>
    </div>;
};