// main libraries
import { useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import styled from "@emotion/styled";

import fullLogo from "@assets/logo-main.svg";
import { Spiner } from "@components/Spinner";
import { Helmet } from "react-helmet-async";
import { Header } from "@components/Header/Header";
import {
  fetchAbout,
  setDescriptionLoading,
} from "@slices/aboutSlice";
import { WrapperWithImage } from "@components/WrapperWithImage";
import { Blur } from "@components/Blur";
import { Button } from "@components/Button";
import { media } from "@styles/media";
import { currentLanguage } from "@locales/i18n";

import styles from "./styles.scss";

export const About = () => {
  const { description, status, locale } = useSelector((state) => state.about);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentLng = currentLanguage();

  useLayoutEffect(() => {
    if (!description.length || locale !== currentLng) {
      dispatch(setDescriptionLoading());
      dispatch(fetchAbout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <title itemProp="name" lang={currentLng}>
          Радикальня | {t("pages.about")}
        </title>
        <meta name="og:description" content={description.slice(0, 150) + "..."}/>
        <meta property="og:type" content="about" />
        <meta property="og:image:type" content="image/webp" />
        <meta property="og:image:width" content="640" />
        <meta property="og:image:height" content="640" />
        <meta property="og:image:alt" content="Radical logo" />
      </Helmet>

      <Header />
      <WrapperWithImage>
        <Blur />
        <div className={styles.container}>
          {status === "loading" && <Spiner />}
          {description && <div className={styles.content}>

            <div className={styles.text}>
              <h1 className={styles.title}>{t("about.title")}</h1>
              <div><ReactMarkdown children={description} /></div>
            </div>

            <div className={styles.contacts}>
              <Logo src={fullLogo} alt="big-logo" />
              <div className={styles.adminLinkWrapper}>
                <h1 className={styles.title}>{t("about.connectAdmin")}</h1>
                <Button link={"https://t.me/Oleg_Rachko"} isExternal={true}>
                  {t("about.button")}
                </Button>
              </div>
            </div>

          </div>}
        </div>

      </WrapperWithImage>
    </>
  );
};

const Logo = styled.img`
  max-width: 500px;
  //height: fit-content;
  ${media.medium} {
    padding: 1rem;
  }
  
  ${media.small} {
    max-width: 100%;
  }
`;