import axios from 'axios';
import {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Button} from '@components/Button';
import {baseUrl} from '@utils/constants';
import {setParam} from '@slices/paramsSlice';
import {setUser} from '@slices/userSlice';
import {useSearchParams} from 'react-router-dom';
import {Error, Input, Title} from '../elements';
import styles from './styles.scss';

export const ResetPassword = () => {

	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();

	const [code] = useState(searchParams.get('code'));
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [formError, setFormError] = useState('');

	//Статус запроса. Возможные значения: waiting, requested
	const [requestStatus, setRequestStatus] = useState('waiting');

	const isPasswordValid = password.length >= 6;
	const isPasswordConfirmationValid = passwordConfirmation == password;
	const isFormValid = isPasswordValid && isPasswordConfirmationValid;

	const {t} = useTranslation();

	useEffect(() => {
		//При измененнии данных формы - убираем ошибку
		setFormError('');
	}, [password, passwordConfirmation]);

	useEffect(() => {
		document.addEventListener('keydown', onKeyDown, false);
		return () => document.removeEventListener('keydown', onKeyDown);
		//Вынесли в отдельный useEffect с зависимостью от данных формы, тк в другом случае провряется всегда начальный state
	}, [password, passwordConfirmation, requestStatus]);

	const onLoginButtonClick = () => {
		if(requestStatus != 'waiting'){
			//Отправлять запрос только если форма находится в ожидании отправки
			return;
		}

		if(isFormValid){
			setRequestStatus('requested');
			setFormError('');

			//API-запрос на авторизацию. Не выношу все в thunks/fetches, чтобы держать все на одной странице. Так проще поддерживать
			//Если нужно - вынесем отдельно
			axios
				.post(baseUrl + '/api/auth/reset-password', {
					code, password, passwordConfirmation
				})
				.then(response => {
					toast.success(t('main.forms.resetPassword.passwordChanged'));
					setPassword('');
					setPasswordConfirmation('');
					toAuth();
				})
				.catch(error => {
					setFormError(error?.response?.data?.error?.message || t('main.forms.errors.requestFailed'));
				})
				.finally(() => {
					setRequestStatus('waiting');
				});
		}else{
			const errors = [];
			if(!isPasswordValid){
				errors.push(t('main.forms.errors.minPasswordLength'));
			}
			if(!isPasswordConfirmationValid){
				errors.push(t('main.forms.errors.specifyValidPasswordConfirmation'));
			}
			if(errors){
				setFormError(errors.join('<br/>'));
			}
		}
	}

	const onKeyDown = e => {
		if (['NumpadEnter', 'Enter'].includes(e.code)) {
			onLoginButtonClick();
		}
	};

	const toAuth = () => {
		dispatch(setParam({param: 'mainPageForm', data: 'auth'}));
	}

	return <div className={styles.resetPassword}>
		<Title>{t('main.forms.resetPassword.title')}</Title>
		<Input labelText={t('main.forms.password.label')} placeholder={t('main.forms.password.placeholder')} onChange={setPassword} value={password} type={'password'} />
		<Input labelText={t('main.forms.passwordConfirmation.label')} placeholder={t('main.forms.passwordConfirmation.placeholder')} onChange={setPasswordConfirmation} value={passwordConfirmation} type={'password'} />
		<Error>{formError}</Error>
		<Button onClick={onLoginButtonClick} disabled={requestStatus == 'requested'}>{t('main.forms.resetPassword.send')}</Button>
		<div className={styles.rememberPasswordWrapper}>{t('main.forms.forgotPassword.rememberPassword')} <span onClick={toAuth}>{t('main.forms.forgotPassword.toAuth')}</span></div>
	</div>;
}