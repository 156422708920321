import {Modal} from "@components/Modules";

import styles from "./styles.scss";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import axios from "axios";
import {baseUrl, headers} from "@utils/constants";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import {Spiner} from "@components/Spinner";
import {addPageData} from "@slices/pageDataSlice";
import {useTranslation} from "react-i18next";

export const GetPrivateAccessModal = (props) => {

    const {user = {}} = useSelector((state) => state.pageData?.private) || {};

    const [requested, setRequested] = useState(false);

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const confirm = () => {
        //Отправялем запрос на продление доступа
        if(requested) {
            return;
        }

        setRequested(true);

        const url = `${baseUrl}/api/users/get-private-access/`;
        axios.get(url, headers())
            .then(response => {
                if(response.data.status){
                    toast.success(t('private.modals.subscription.success'));
                    props.onClose();
                    //Обновим данные пользователя
                    axios.get(`${baseUrl}/api/users/me`, headers())
                        .then(response => {
                            dispatch(addPageData({page: 'private', data: {user: response.data}}));
                        });
                }else{
                    toast.error(response.data.data.message);
                }
            })
            .catch(error => {
                toast.error(t('common.errors.requestFailed'));
            })
            .finally(() => setRequested(false));
    };

    const currentPrivateAccessDate = user?.private_access_date;
    const accessDateToAdd = currentPrivateAccessDate > new Date().toISOString() ? currentPrivateAccessDate : new Date().toISOString();
    const newPrivateAccessDate = dayjs(accessDateToAdd).add(1, 'month');

    const hasEnoughMoney = user?.balance >= 5;

    return <Modal opened={props.opened} onClose={props.onClose} className={styles.getPrivatAccessModal}>
        <div className={styles.getPrivatAccessModalContent}>
            <div className={styles.title}>{t('private.modals.subscription.title')}</div>
            <div className={styles.section}>
                <div className={styles.subtitle}>{t('private.modals.subscription.currentDate')}: </div>
                <div className={styles.value}>{currentPrivateAccessDate ? dayjs(currentPrivateAccessDate).format('DD.MM.YYYY HH:mm') : ' - '}</div>
            </div>
            <div className={styles.section}>
                <div className={styles.subtitle}>{t('private.modals.subscription.newDate')}: </div>
                <div className={styles.value}>{dayjs(newPrivateAccessDate).format('DD.MM.YYYY HH:mm')}</div>
            </div>
            <div className={styles.section}>
                <div className={styles.subtitle}>{t('private.modals.subscription.amount')}: </div>
                <div className={styles.value}>5 usd</div>
            </div>
            <div className={styles.section}>
                <div className={styles.subtitle}>{t('private.modals.subscription.balance')}: </div>
                <div className={styles.value}>{user?.balance} usd</div>
            </div>
            {hasEnoughMoney ? <div className={styles.section}>
                {t('private.modals.subscription.amountWillBeCharged')}
            </div> : <div className={classNames(styles.section, styles.error)}>
                {t('private.modals.subscription.insufficientFunds')}
            </div>}
            {hasEnoughMoney && <div className={styles.buttons}>
                <div className={styles.button} onClick={confirm}>{t('private.modals.subscription.buttons.confirm')}</div>
                <div className={classNames(styles.button, styles.cancel)} onClick={props.onClose}>{t('private.modals.subscription.buttons.cancel')}</div>
            </div>}
        </div>

        {requested && <div className={styles.overlay}>
            <Spiner/>
        </div>}
    </Modal>
};