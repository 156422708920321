import styles from './styles.scss';
import classnames from "classnames";
import {useEffect} from "react";

export const Pagination = props => {

  const {
    page = 1,//Текущая страница
    total = 0,//Общее количество элементов
    pageCount = 0,//Количество страниц
    onPageChange = page => {},//Функция, которая вызывается при смене страницы
  } = props;

  useEffect(() => {
    if(pageCount < 1){
      //Если страниц нет, то ничего не делаем
      return;
    }
    //Если текущая страница больше общего количества страниц, то переключимся на последнюю страницу
    if(page > pageCount){
      onPageChange(pageCount);
    }
    //Если текущая страница меньше 1, то переключимся на первую страницу
    if(page < 1){
      onPageChange(1);
    }

  }, [page, pageCount]);

  if(pageCount < 2){
    //Если страниц меньше двух, то пагинация не нужна
    return null;
  }

  //Рендерим 2 страницы назад и 2 вперед
  //Рендерим многоточия

  //Рендерим предыдущую и следующую страницу
  const needPrev = page > 1;
  const needNext = page < pageCount;

  //Рендерим первую и последнюю страницу
  const needFirst = page > 3;
  const needLast = page < pageCount - 2;

  return <div className={styles.pagination}>
    {needPrev && <div className={styles.page} onClick={() => onPageChange(page - 1)}>Предыдущая</div>}
    {needFirst && <div className={styles.page} onClick={() => onPageChange(1)}>1</div>}
    {page >= 5 && <div className={classnames(styles.page, styles.disabled)}> ... </div>}
    {page >= 3 && <div className={styles.page} onClick={() => onPageChange(page - 2)}>{page - 2}</div>}
    {page >= 2 && <div className={styles.page} onClick={() => onPageChange(page - 1)}>{page - 1}</div>}
    <div className={classnames(styles.page, styles.current)}>{page}</div>
    {page <= pageCount - 1 && <div className={styles.page} onClick={() => onPageChange(page + 1)}>{page + 1}</div>}
    {page <= pageCount - 2 && <div className={styles.page} onClick={() => onPageChange(page + 2)}>{page + 2}</div>}
    {page <= pageCount - 4 && <div className={classnames(styles.page, styles.disabled)}> ... </div>}
    {needLast && <div className={styles.page} onClick={() => onPageChange(pageCount)}>{pageCount}</div>}
    {needNext && <div className={styles.page} onClick={() => onPageChange(page + 1)}>Следующая</div>}
  </div>;
};