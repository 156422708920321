import styles from './styles.scss';
import {Helmet} from "react-helmet-async";
import {Header} from "@components/Header/Header";
import {Wrapper} from "@components/Wrapper";
import {baseUrl, token} from "@utils/constants";
import {useEffect, useState} from "react";
import {addPageData, setPageData} from "@slices/pageDataSlice";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {Spiner} from "@components/Spinner";
import {getPrivatePosts} from "@utils/fetches";
import toast from "react-hot-toast";
import {Pagination} from "@components/Modules";
import {useNavigate, useParams} from "react-router-dom";
import {PrivateAccessStatus} from "@pages/Private/parts";
import classnames from "classnames";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

export const Private = () => {

    const {user = {}, posts = [], pagination = {}} = useSelector((state) => state.pageData?.private) || {};

    const [pageLoaded, setPageLoaded] = useState(false);

    const {page = 1} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {t} = useTranslation();

    const language = i18n.language;

    //Проверим, есть ли у пользователя приватный доступ
    const hasPrivateAccess = user?.private_access_date && dayjs(user?.private_access_date).unix() > dayjs().unix();

    useEffect(() => {
        dispatch(setPageData({page: 'private', data: {}}));

        getPrivatePosts(page).then(response => {
            dispatch(addPageData({page: 'private', data: response.data}));
            setPageLoaded(true);
        }).catch(error => {
            toast.error(t('common.errors.requestFailed'));
        });

    }, [token, language]);

    const onPageChange = (page) => {
        navigate(`/private/${page}`);
    };

    const onCardClick = (id) => {
        navigate(`/private/post/${id}`);
    };

    const onError = (e) => {
        e.currentTarget.src = require(`@assets/default.webp`);
    };

    return <>
        <Helmet>
            <title>Радикальня | {t('pages.private')}</title>
        </Helmet>

        <Header/>
        <Wrapper>
            {pageLoaded ? <div className={styles.private}>
                <PrivateAccessStatus/>
                <h1>{t('private.title')}</h1>
                {hasPrivateAccess && <>
                    <div className={styles.posts}>
                        {posts?.map(post => <div key={post.id} className={styles.card} onClick={() => onCardClick(post.id)}>
                            <img src={baseUrl + post.cover?.url} alt={post.title} onError={onError} />
                            <div className={styles.content}>
                                <div className={styles.title}>{post.title}</div>
                                <div className={styles.views}>{t('details.views')}: {post.views}</div>
                                <div className={styles.description}>{post.description}</div>
                            </div>
                        </div>)}
                    </div>
                    <Pagination {...pagination} onPageChange={onPageChange}/>
                </>}
                {!hasPrivateAccess && <div className={styles.noAccess}>
                    <div className={classnames(styles.fakeCards, styles.posts)}>
                        <div className={styles.card}>
                            <img src={require(`@assets/mock/1.jpg`)} alt=''/>
                            <div className={styles.content}>
                                <div className={styles.title}>Алина Иванова</div>
                                <div className={styles.views}>Просмотров: 443</div>
                                <div className={styles.description}>
                                    Алина — типичная блондинка с накладными ресницами и переоценённым мнением о себе. Любит болтать, но чаще всего говорит лишь о себе. Дешёвый маникюр и бесконечные разговоры о «шике» и «красоте» — её главный стиль.
                                </div>
                            </div>
                        </div>
                        <div className={styles.card}>
                            <img src={require(`@assets/mock/2.jpg`)} alt=''/>
                            <div className={styles.content}>
                                <div className={styles.title}>Карина Дягиль</div>
                                <div className={styles.views}>Просмотров: 443</div>
                                <div className={styles.description}>
                                    Карина — вечно пытается казаться лучше, чем есть на самом деле. Считает себя роковой брюнеткой, но в реальности выглядит неопрятно и вульгарно. Занимается саморекламой, хотя ничего особенного предложить не может.
                                </div>
                            </div>
                        </div>
                        <div className={styles.card}>
                            <img src={require(`@assets/mock/3.jpg`)} alt=''/>
                            <div className={styles.content}>
                                <div className={styles.title}>София Краснокутская</div>
                                <div className={styles.views}>Просмотров: 443</div>
                                <div className={styles.description}>
                                    София — любит притворяться загадочной и умной, но за этой маской скрывается просто скучная и банальная личность. Шатенка с постоянным недовольством на лице, которая явно переоценивает свои «таланты».
                                </div>
                            </div>
                        </div>
                        <div className={styles.card}>
                            <img src={require(`@assets/mock/4.jpg`)} alt=''/>
                            <div className={styles.content}>
                                <div className={styles.title}>Диана Лазарева</div>
                                <div className={styles.views}>Просмотров: 443</div>
                                <div className={styles.description}>
                                    Диана — рыжеволосая неформалка, которая думает, что её резкость и хамство — это признак независимости и харизмы. Часто перебарщивает с макияжем, чтобы скрыть свои недостатки, но это лишь делает её более дешёвой.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.info}>
                        <div>{t('private.noAccess.title')}</div>
                        <div>{t('private.noAccess.text')}</div>
                        <div>{t('private.cost')}: 5 usd {t('private.period')}</div>
                    </div>
                </div>}
            </div> : <Spiner/>}
        </Wrapper>
    </>;
};