import axios from 'axios';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Button} from '@components/Button';
import {baseUrl} from '@utils/constants';
import {setParam} from '@slices/paramsSlice';
import {setUser} from '@slices/userSlice';
import {Error, Input, Title} from '../elements';
import styles from './styles.scss';

export const Registration = () => {

	const dispatch = useDispatch();

	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [formError, setFormError] = useState('');

	const [requestStatus, setRequestStatus] = useState('waiting');

	const isUsernameValid = username.length >= 4;
	const isPasswordValid = password.length >= 6;
	const isEmailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

	const isFormValid = isEmailValid && isPasswordValid && isUsernameValid;

	const {t} = useTranslation();

	useEffect(() => {
		//При измененнии данных формы - убираем ошибку
		setFormError('');
	}, [email, password, username]);

	useEffect(() => {
		document.addEventListener('keydown', onKeyDown, false);
		return () => document.removeEventListener('keydown', onKeyDown);
		//Вынесли в отдельный useEffect с зависимостью от данных формы, тк в другом случае провряется всегда начальный state
	}, [email, password, username, requestStatus]);

	const onLoginButtonClick = () => {
		if(requestStatus != 'waiting'){
			//Отправлять запрос только если форма находится в ожидании отправки
			return;
		}

		if(isFormValid){
			setRequestStatus('requested');
			setFormError('');

			//API-запрос на авторизацию. Не выношу все в thunks/fetches, чтобы держать все на одной странице. Так проще поддерживать
			//Если нужно - вынесем отдельно

			axios
				.post(baseUrl + '/api/auth/local/register', {
					password: password,
					email: email,
					username: username//Обязательное поле для Strapi
				})
				.then(response => {
					dispatch(setUser({token: response.data.jwt, data: response.data.user}))
				})
				.catch(error => {
					let message = t('main.forms.errors.requestFailed');

					if(error.response?.data?.error?.message?.length >= 1){
						//Strapi не мог еще миллион уровней вложенности сделать?)
						message = error.response.data.error.message;
					}

					if(error.response?.data?.error?.details?.errors?.length >= 1){
						//Strapi не мог еще миллион уровней вложенности сделать?)
						message = error.response.data.error.details.errors.map(e => e.message).join('<br/>');
					}
					setFormError(message);
				})
				.finally(() => {
					setRequestStatus('waiting');
				});

		}else{
			const errors = [];
			if(!isEmailValid){
				errors.push(t('main.forms.errors.specifyValidEmail'));
			}
			if(!isPasswordValid){
				errors.push(t('main.forms.errors.minPasswordLength'));
			}
			if(!isUsernameValid){
				errors.push(t('main.forms.errors.minUsernameLength'));
			}
			if(errors){
				setFormError(errors.join('<br/>'));
			}
		}
	}


	const onKeyDown = e => {
		if (['NumpadEnter', 'Enter'].includes(e.code)) {
			onLoginButtonClick();
		}
	};

	const toAuth = () => {
		dispatch(setParam({param: 'mainPageForm', data: 'auth'}))
	}

	return <div className={styles.registration}>
		<Title>{t('main.forms.registration.title')}</Title>
		<Input labelText={t('main.forms.username.label')} placeholder={t('main.forms.username.placeholder')} onChange={setUsername} value={username} />
		<Input labelText={t('main.forms.email.label')} placeholder={t('main.forms.email.placeholder')} onChange={setEmail} value={email} />
		<Input type={'password'} labelText={t('main.forms.password.label')} onChange={setPassword} placeholder={t('main.forms.password.placeholder')}/>
		<Error>{formError}</Error>
		<Button onClick={onLoginButtonClick} disabled={requestStatus == 'requested'}>{t('main.forms.registration.button')}</Button>
		<div className={styles.haveAccountWrapper}>{t('main.forms.registration.hasAccount')} <span className={styles.toAuth} onClick={toAuth}>{t('main.forms.registration.login')}</span></div>
	</div>;
}