/**
 * @component
 * Компонент плеера. Обертка для ReactPlayer, с кастомными элементами управления
 * @param {Object} props - Свойства компонента.
 * @param {string} props.url - Ссылка на видео.
 */

import styles from './styles.scss';
import ReactPlayer from "react-player";
import {useEffect, useRef, useState} from "react";
import classNames from "classnames";

export const Player = ({url = ''}) => {

    const wrapperRef = useRef();
    const playerRef = useRef();

    const timerRef = useRef();

    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8);
    const [muted, setMuted] = useState(false);
    const [played, setPlayed] = useState(0);
    const [loaded, setLoaded] = useState(0);
    const [fullscreen, setFullscreen] = useState(false);

    const [panelVisible, setPanelVisible] = useState(false);
    const [mouseIdle, setMouseIdle] = useState(false);

    const togglePlaying = () => {
        setPlaying(playing => !playing);
    };

    const toggleMuted = () => {
        setMuted(muted => !muted);
    };

    const handleProgress = (progressState) => {
        setPlayed(progressState.played);
        setLoaded(progressState.loaded);
    };

    const onSeekChange = (e) => {
        playerRef.current.seekTo(parseFloat(e.target.value), 'fraction');
    };

    const volumeLevel = () => {
        if (volume <= 0 || muted) return 0;
        if (volume < 0.25) return 1;
        if (volume < 0.5) return 2;
        return 3;
    }

    const enableFullscreen = () => {
        setFullscreen(true);
        wrapperRef.current?.requestFullscreen();
        document.addEventListener('fullscreenchange', () => {
            if (!document.fullscreenElement) {
                setFullscreen(false);
            }
        });
    };

    const disableFullscreen = () => {
        setFullscreen(false);
        document.exitFullscreen();
    };

    const showPanel = () => {
        setPanelVisible(true);
    };

    //Скрытие панели управления при движении мыши
    useEffect(() => {

        const handleMouseMove = () => {
            setMouseIdle(false); // Сбрасываем состояние неактивности
            if (timerRef.current) {
                clearTimeout(timerRef.current); // Сбрасываем таймер
            }

            // Устанавливаем новый таймер на 3 секунды
            timerRef.current = setTimeout(() => {
                setMouseIdle(true); // Устанавливаем состояние неактивности
            }, 3000);
        };

        //Слушаем событие движения мыши
        wrapperRef.current?.addEventListener('mousemove', handleMouseMove);

        //При нахождении мыши на плейере - показываем панель управления
        wrapperRef.current?.addEventListener('mousemove', showPanel);


        return () => {
            // Отписываемся от событий
            wrapperRef.current?.removeEventListener('mousemove', showPanel);
            wrapperRef.current?.removeEventListener('mousemove', handleMouseMove);

            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if(mouseIdle){
            setPanelVisible(false);
        }
    }, [mouseIdle]);

    useEffect(() => {
        //инициализация плеера при смене ссылки

    }, [url]);

    return <div className={styles.wrapper} ref={wrapperRef} onContextMenu={(e) => e.preventDefault()}>
        <div className={styles.controls}>
            <div className={styles.togglePlaying} onClick={togglePlaying}>
                {playing || <img src={require('@assets/play.webp')} alt=""/>}
            </div>
            <div className={classNames(styles.panel, panelVisible && styles.visible)}>
                <div className={styles.playing} onClick={togglePlaying}>
                    {playing
                        ? <img src={require('@assets/icons/red-pause.png')} alt=""/>
                        : <img src={require('@assets/icons/red-play.png')} alt=""/>
                    }
                </div>
                <div className={styles.time}>
                    <div className={styles.progress}>
                        <div className={styles.loaded} style={{width: loaded * 100 + '%'}}></div>
                        <input type="range" min={0} max={1} step="0.01" value={played} onChange={onSeekChange}/>
                    </div>
                </div>

                <div className={styles.volume}>
                    <img src={require(`@assets/icons/red-volume-${volumeLevel()}.png`)} alt="" onClick={toggleMuted}/>
                    <input type="range" min={0} max={1} step="0.01" value={volume}
                           onChange={(e) => setVolume(e.target.value)}/>
                </div>

                <div className={styles.fullscreen}>
                    {fullscreen
                        ? <img src={require(`@assets/icons/red-minimize.png`)} alt="" onClick={disableFullscreen}/>
                        : <img src={require(`@assets/icons/red-expand.png`)} alt="" onClick={enableFullscreen}/>
                    }
                </div>
            </div>
        </div>
        <ReactPlayer url={url} muted={muted} volume={volume} onProgress={handleProgress} key={url}
                     playing={playing} width={'100%'} height={'100%'} onEnded={() => setPlaying(false)}
                     progressInterval={100} ref={playerRef} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        />
        <div className={styles.overlay}></div>
    </div>;
};

