import { useEffect } from "react";
import styled from "@emotion/styled";
import { Button } from "./Button";
import { useSelector } from "react-redux";

export const TelegramButton = ({ dataOnauth, botId, children }) => {
  const { error } = useSelector((state) => state.user);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?21";
    script.setAttribute("data-auth-url", "https://radical-plum.com/auth/callback");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleTelegramAuth = () => {

    // URL для авторизации через Telegram (замените на ваш bot_username и redirect URL)
    const telegramAuthUrl = `https://oauth.telegram.org/auth?bot_id=${botId}&origin=https://radical-plum.com/auth/callback/`;

    // Открытие нового окна с авторизацией Telegram
    window.location.href = telegramAuthUrl;
    return;

    return window.Telegram.Login.auth(
      { bot_id: botId, request_access: "read" },

    );
  };

  return (
    <StyledTelegramButtonWrapper>
      <Button as="button" onClick={handleTelegramAuth}>
        {children}
      </Button>
      {!!error && <p>{error}</p>}
    </StyledTelegramButtonWrapper>
  );
};

const StyledTelegramButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;
