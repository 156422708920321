import {WhoreLink} from '@pages/Details/Components';
import {getVideoCDNLinks} from '@utils/fetches';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Header } from "@components/Header/Header";
import { useEffect } from "react";
import { Footer } from "@components/Footer/Footer";
import { Wrapper } from "@components/Wrapper";
import { Helmet } from "react-helmet-async";
import { BackButton } from "@components/BackButton";
import styled from "@emotion/styled";
import { Heading } from "@components/Heading";
import ReactMarkdown from "react-markdown";
import { FriendsLink } from "./Components/FriendsLink";
import { useDispatch } from "react-redux";
import {addCDNLinks, clearPost, fetchPost, setLoading} from "@slices/postSlice";
import { media, sizes } from "@styles/media";
import { Spiner } from "@components/Spinner";
import { ErrorMessage } from "@components/ErrorMessage";
import { Carousel } from "./Components/Carousel/Carousel";
import { currentLanguage } from "@locales/i18n";
import { baseUrl } from "@utils/constants";
import defaultPic from "@assets/default.webp";
import {Comments} from "./Components/comments";
import {Wallets} from './Components/wallets';
import styles from './styles.scss';


export const Details = () => {
  const { t } = useTranslation();
  const { leakId, page } = useParams();
  const navigate = useNavigate();
  const { post, status, error, linkId } = useSelector((state) => state.post);
  const dispatch = useDispatch();
  const currentLng = currentLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (linkId !== leakId || !Object.keys(post).length) {
      dispatch(setLoading());
      dispatch(fetchPost(leakId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(clearPost());
    };
  }, []);

  useEffect(() => {
    if(status == 'succeeded'){
      //Запрашиваем данные по ссылкам на CDN
      const videoIDs = post?.attachments?.data?.filter(item => item?.attributes?.mime?.includes('video')).map(item => item.id) || [];

      if(videoIDs.length){
        getVideoCDNLinks(videoIDs).then(data => {
          dispatch(addCDNLinks(data));
        })
      }
    }

  }, [status]);

  return (
    <>
      <Helmet>
        <title itemProp="name" lang={currentLng}>
          Радикальня | {t("pages.leaks")}
        </title>
        <meta property="og:type" content="about" />
        <meta property="og:image" content={baseUrl + defaultPic} />
        <meta property="og:image:secure_url" content={baseUrl + defaultPic} />
        <meta property="og:image:type" content="image/webp" />
        <meta property="og:image:width" content="640" />
        <meta property="og:image:height" content="640" />
        <meta property="og:image:alt" content="Radical logo" />
      </Helmet>
      <Header />
      <Wallets/>
      <DetailsWrapper className={status === "loading" || error ? "empty" : ""}>
        <DetailsBackButton onClick={() => navigate(`/leaks/${page}`, { state: leakId })}/>
        <ContentWrapper className={status === "loading" || error ? "empty" : ""}>
          {status === "loading" && <Spiner />}
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {!!Object.keys(post).length && (
            <>
              <DetailsHeading>{post.title}</DetailsHeading>
              <Carousel post={post} />
              <div className={styles.textAndCommentsWrapper}>
                <div className={styles.text}>
                  <div className={styles.viewsQty}>{t('details.views')}: <span>{+post.views + 1}</span></div>
                  <ReactMarkdown children={post.description} />
                  {!!post.friend_list.data && (
                    <>
                      <LinksHeading>{t("details.links")}</LinksHeading>
                      <WhoreLink/>
                      <FriendsLink linkPath={post.friend_list.data.attributes.url} />
                    </>
                  )}
                </div>
                <Comments/>
              </div>
            </>
          )}
        </ContentWrapper>
      </DetailsWrapper>
      <Footer />
    </>
  );
};

const DetailsHeading = styled(Heading)`
  font-size: 2rem;
  margin: 1rem 0;
`;

const LinksHeading = styled(Heading)`
  margin: 1rem 0;
  font-size: 1.5rem;
`;

const DetailsBackButton = styled(BackButton)`
  align-self: flex-start;
`;

const DetailsWrapper = styled(Wrapper)`
  max-width: ${sizes.large}px;
  margin: auto;
  padding: 2rem 4rem 2rem 4rem;
  display: block;

  &.empty {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-content: center;
  }

  ${media.small} {
    padding: 2rem 1rem 0 1rem;
  }
`;

const ContentWrapper = styled.section`
  &.empty {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
