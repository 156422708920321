import defaultPic from '@assets/default.webp';
import {baseUrl} from '@utils/constants';
import {useState} from 'react';

export const LeakCover = ({img, title, className}) => {

  const [loaded, setLoaded] = useState(false);

  const onError = (e) => {
    e.currentTarget.src = defaultPic;
  }

  const onLoad = (e) => {
    setLoaded(true);
  }

  return <img src={loaded ? baseUrl + img : defaultPic} alt={title} onLoad={onLoad} onError={onError} className={className} />
};