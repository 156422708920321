import {createSlice} from "@reduxjs/toolkit";

// Define the initial state
const initialState = {};

// Create a slice
const pageDataSlice = createSlice({
  name: "pageData",
  initialState,
  reducers: {
    setPageData: (state, action) => {
      const page = action.payload.page;
      state[page] = action.payload.data;
    },
    addPageData: (state, action) => {
      const page = action.payload.page;
      state[page] = {...state[page], ...action.payload.data};
    },
  },
});

export const {setPageData, addPageData} = pageDataSlice.actions;

export default pageDataSlice.reducer;
