import {ProfileEdit} from '@pages/Profile';
import { useTranslation } from "react-i18next";
import { Main } from "@pages/Main/Main";
import { About } from "@pages/About/About";
import { Slivi } from "@pages/Plums/Plums";
import { Details } from "@pages/Details/Details";
import {Private} from "@pages/Private";
import {Post as PrivatePost} from "@pages/Private/Post";

export const usePages = () => {
  const { t } = useTranslation();

  const pages = [
    {
      path: "/",
      element: <Main />,
      link: "/",
      isProtected: false,
      inHeader: true,
      name: t("pages.main"),
    },
    {
      path: "/about",
      link: "/about",
      element: <About />,
      isProtected: true,
      inHeader: true,
      name: t("pages.about"),
    },
    {
      path: "/leaks/:page",
      link: `/leaks/${1}`,
      element: <Slivi />,
      isProtected: true,
      inHeader: true,
      name: t("pages.leaks"),
    },
    {
      path: "/:page/details/:leakId",
      element: <Details />,
      isProtected: true,
      inHeader: false,
      name: t("pages.details"),
    },
    {
      path: "/profile/edit",
      element: <ProfileEdit />,
      link: "/profile/edit",
      isProtected: true,
      inHeader: true,
      name: t("pages.profile")
    },
    {
      path: "/private/:page?",
      element: <Private />,
      link: "/private",
      isProtected: true,
      inHeader: true,
      code: 'private',
      name: '🔥 Private'
    },
    {
      path: "/private/post/:id?",
      element: <PrivatePost />,
      isProtected: true,
    },
    {
      link: "https://t.me/+xzup7dizlkQyMjRi",
      isProtected: true,
      inHeader: true,
      name: 'Telegram',
      code: 'telegram',
      isExternal: true,
    },
  ];

  return pages;
};
