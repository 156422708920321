import {prepareQueryString} from '@utils/helpers';
import axios from "axios";
import { baseUrl, headers } from "./constants";
import fileDownload from "js-file-download";
import { currentLanguage } from "@locales/i18n";

export const getAbout = () =>
  axios.get(`${baseUrl}/api/about?locale=${currentLanguage()}`, headers());

export const getPosts = (page = 1) => {

  const query = {
    populate: {
      cover: '*'
    },
    filters: {
      $or: [
        {private: 0},
        {private: {$null: true}}
      ]
    },
    sort: ['id:desc'],

    pagination: {page, pageSize: 12},
    locale: currentLanguage()
  };

  return axios.get(`${baseUrl}/api/posts?${prepareQueryString(query)}`, headers());
}

export const getPrivatePosts = (page = 1) => {
  return axios.get(`${baseUrl}/api/private/posts/?page=${page}&locale=${currentLanguage()}`, headers()).then(res => res.data);
}

export const getPrivatePost = (id) => {
  //Делаем кастомный роут, чтобы получить приватный пост
  //Так как нужно ограничивать доступ к таким постам на уровне сервера
  return axios.get(`${baseUrl}/api/private/post/${id}`, headers()).then(res => res.data);
};
export const getMainPage = () => {
  //Делаем кастомный роут, чтобы получить в том числе только НЕ приватные посты
  //Так как нужно ограничивать доступ к таким постам на уровне сервера
  return axios.get(`${baseUrl}/api/pages/main/?locale=${currentLanguage()}`, headers()).then(res => res.data);
};

export const getAttachment = (id) => {
    return axios.get(`${baseUrl}/api/private/attachment/${id}`, {...headers(), responseType: 'blob'}).then(res => URL.createObjectURL(res.data)).catch(err => require('@assets/default.webp'));
};

export const getPost = (leakId) => {
  const query = {
    populate: {
      comments: {populate: 'user'},
      attachments: '*',
      friend_list: '*',
      cover: '*'
    },
    fields: ['*'],
    locale: currentLanguage()
  };
  return axios.get(`${baseUrl}/api/posts/${leakId}?${prepareQueryString(query)}`, headers());
}
export const getVideoCDNLinks = videoIDs => {
  const query = {
    filters: {
      videoID: {$in: videoIDs}
    },
    fields: ['link', 'videoID']
  };
  return axios.get(`${baseUrl}/api/video-cdn-links?${prepareQueryString(query)}`, headers()).then(res => {
    const links = {};
    res?.data?.data && res.data.data.forEach(item => links[+item.attributes.videoID] = item.attributes.link);
    return links;
  });
}

export const getWallets = () => axios.get(`${baseUrl}/api/wallets`, headers());

export const tgAuth = (response) =>
  axios.post(`${baseUrl}/api/users-permissions/telegram`, response);

export const handleDownload = (url, filename) => {
  axios.get(url);
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, filename);
    })
    .catch((err) => console.warn(err));
};


