import React from 'react';
import classnames from 'classnames';

import styles from './styles.scss';
import {useNavigate} from "react-router-dom";

export const Button = (props) => {
    const {style, children, color = 'yellow', disabled = false} = props;

    const navigate = useNavigate();

    const onClick = () => {

        if(disabled){
            return null;
        }

        if(props.open){
            window.open(props.open);
        }

        if(props.link){
            window.location.href = props.link;
        }

        if(props.navLink){
            navigate(props.navLink);
        }

        if(typeof props.onClick == 'function'){
            props.onClick();
        }

        return null;
    };

    const className = classnames(styles.button, styles[color], props.className,  {
        [styles.disabled]: disabled
    });

    return <div className={className} style={{...style}} onClick={onClick}>
        {children}
    </div>;
};
