import {Button} from '@components/Button';
import {Footer} from '@components/Footer/Footer';
import {Header} from '@components/Header/Header';
import {Spiner} from '@components/Spinner';
import {currentLanguage} from '@locales/i18n';
import {Input} from '@pages/Main/parts/forms/elements';
import {setPageData} from '@slices/pageDataSlice';
import {baseUrl, headers} from '@utils/constants';
import axios from 'axios';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import toast from 'react-hot-toast';
import {useDispatch, useSelector} from 'react-redux';
import ImageUploading from 'react-images-uploading';

import styles from './styles.scss';

export const Edit = () => {

	const pageData = useSelector(state => state.pageData['profile.edit']) || {};
	console.log('pageData', pageData);
	const currentLng = currentLanguage();
	const dispatch = useDispatch();

	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');

	const [currentPassword, setCurrentPassword] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');

	const [requested, setRequested] = useState(false);

	const [photo, setPhoto] = useState(null);

	const onChange = (imageList, addUpdateIndex) => {
		setPhoto(imageList[0]);
	};

	useEffect(() => {
		//Получение данных профиля
		axios
			.get(`${baseUrl}/api/users/me?fields[0]=username&fields[1]=email&fields[2]=photo_url`, headers())
			.then(response => {
				dispatch(setPageData({page: 'profile.edit', data: response.data}));
				setUsername(response.data.username);
				setEmail(response.data.email);
			});
	}, []);

	const saveProfileData = async () => {
		if(requested){
			return;
		}
		setRequested(true);

		let photo_url = pageData.photo_url;

		//Сперва загрузим аватар, если он есть
		if(photo){
			const formData = new FormData();
			formData.append('files', photo.file);

			photo_url = await axios
				.post(`${baseUrl}/api/upload`, formData, headers())
				.then(response => {
					//возвращаем ссылку на загруженный файл
					return response.data[0]?.url;
				}).catch(error => {
					toast.error('Ошибка загрузки аватара');
					return pageData.photo_url;
				});
		}

		axios
			.put(`${baseUrl}/api/users/${pageData.id}`, {
				username, email, photo_url
			}, headers())
			.then(response => {
				toast.success(t('profile.edit.profileDataSaved'));
			}).catch(error => {
				let message = t('common.errors.requestFailed');

				if(error.response?.data?.error?.message?.length >= 1){
					//Strapi не мог еще миллион уровней вложенности сделать?)
					message = t('strapi.errors.' + error.response.data.error.message);
				}

				if(error.response?.data?.error?.details?.errors?.length >= 1){
					//Strapi не мог еще миллион уровней вложенности сделать?)
					message = error.response.data.error.details.errors.map(e => t('strapi.errors.' + e.message)).join('<br/>');
				}
				toast.error(() => <div dangerouslySetInnerHTML={{__html: message}}/>);
			}).finally(() => {
				setRequested(false);
			});
	};

	const changePassword = () => {
		if(requested){
			return;
		}
		setRequested(true);
		axios
			.post(`${baseUrl}/api/auth/change-password`, {
				currentPassword, password, passwordConfirmation
			}, headers())
			.then(response => {
				toast.success(t('profile.edit.passwordChanged'));
				setCurrentPassword('');
				setPassword('');
				setPasswordConfirmation('');
			}).catch(error => {
				let message = t('common.errors.requestFailed');

				//TODO - вынести в отдельный метод обработку ошибок от Strapi. Часто повторяется и много кода
				if(error.response?.data?.error?.message?.length >= 1){
					//Strapi не мог еще миллион уровней вложенности сделать?)
					message = t('strapi.errors.' + error.response.data.error.message);
				}

				if(error.response?.data?.error?.details?.errors?.length >= 1){
					//Strapi не мог еще миллион уровней вложенности сделать?)
					message = error.response.data.error.details.errors.map(e => t('strapi.errors.' + e.message)).join('<br/>');
				}
				toast.error(() => <div dangerouslySetInnerHTML={{__html: message}}/>);
			}).finally(() => {
				setRequested(false);
			});

	}

	return <>
		<Helmet>
			<title itemProp="name" lang={currentLng}>
				Радикальня | {t('profile.edit.title')}
			</title>
			<meta name="og:description" content={'Редактирование профиля'} />
			<meta property="og:type" content="about" />
			<meta property="og:image:type" content="image/webp" />
			<meta property="og:image:width" content="640" />
			<meta property="og:image:height" content="640" />
			<meta property="og:image:alt" content="Radical logo" />
		</Helmet>
		<Header/>
		<div className={styles.profileEdit}>
			<div className={styles.changeData}>
				<div className={styles.sectionTitle}>{t('profile.edit.editData')}</div>
				<div className={styles.form}>
					<Input labelText={t('profile.edit.username')} value={username} onChange={setUsername} />
					<Input labelText={t('profile.edit.email')} value={email} onChange={setEmail}/>

					<div className={styles.avatar}>
						<div className={styles.title}>{t('profile.edit.avatar')}</div>
						<div className={styles.field}>

							<ImageUploading
								value={photo ? [photo] : []}
								onChange={onChange}
								maxNumber={1}
							>
								{({onImageRemoveAll, onImageUpdate}) => <>
									{<img src={photo ? photo.dataURL : (pageData.photo_url ? baseUrl + pageData.photo_url : require('@assets/default.webp'))} alt={''}/>}
									<div className={styles.buttons}>
										<Button onClick={onImageUpdate} className={styles.button}>
											{t('profile.edit.upload')}
										</Button>
										{photo && <Button onClick={onImageRemoveAll} className={styles.button}>
											{t('profile.edit.delete')}
										</Button>}
									</div>
								</>
								}
							</ImageUploading>

						</div>
					</div>


					<Button onClick={saveProfileData} disabled={requested}>{t('profile.edit.save')}</Button>
				</div>
			</div>
			<div className={styles.changePassword}>
				<div className={styles.sectionTitle}>{t('profile.edit.changePassword')}</div>
				<div className={styles.form}>
					<Input labelText={t('profile.edit.currentPassword')} type={'password'} value={currentPassword} onChange={setCurrentPassword}/>
					<Input labelText={t('profile.edit.newPassword')} type={'password'} value={password} onChange={setPassword}/>
					<Input labelText={t('profile.edit.passwordConfirmation')} type={'password'} value={passwordConfirmation} onChange={setPasswordConfirmation}/>
					<Button onClick={changePassword}>{t('profile.edit.save')}</Button>
				</div>
			</div>
		</div>
		<Footer/>
	</>;
};