import qs from 'qs';
import * as Sentry from '@sentry/react';
import packJson from '../../package.json';

export const prepareQueryString = (params) => {
  return qs.stringify(params,{encodeValuesOnly: true});
}

export const initSentry = () => {
  //Инициализация отправок ошибок в Sentry
  Sentry.init({
    dsn: "https://95dc1179dd43380fc635c6d96b196603@o4506723341893632.ingest.sentry.io/4506723347922944",
    release: packJson.version,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};